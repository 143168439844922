import {
    Box,
    Button,
    Flex,
    ListItem,
    Stack, Text,
    UnorderedList
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

export const TreatmentDetails = ({name, symptoms, whatYouNeed, commonMedications, removeMargin=false}) => {

  // Mapping of short names to longer descriptions
  const whatYouNeedDescriptions = {
    'Meds': 'Current medication list (Example: Lipitor, 10mg, daily at night)',
    'Allergies': 'Medication and food allergies (Example: penicillin, peanuts)',
    'Conditions': 'Medical conditions (Example: High blood pressure, diabetic)',
    'Picture Condition': 'Picture of your condition',
    'BP Reading': 'Blood pressure reading',
    'PhotoOfTest': 'Picture of at home test results',
    'Lab Result': 'Any recent lab results',
    'Rx Picture': 'Picture of your prescription',
    'Thermometer': 'Thermometer to take temperature',
    'PCP': 'Primary Care Physician Information - your doctor’s name and telephone number',
    'Vitals': 'Recent blood pressure, pulse rate, and temperature readings',
  };

  // Function to get the long description
  const getLongDescription = (shortName) => {
    return whatYouNeedDescriptions[shortName] || shortName; // Fallback to shortName if no mapping is found
  }

  console.log("symptoms",symptoms,"name",name)
  const { pathname } = useLocation();
  console.log(pathname)

  return (
    <>
      <Flex
        as="section"
        background="white"
        pt={{ base: 6, md: 8 }}
        pb={removeMargin ? 0 : { base: 2, md: 8 }}
        mx={removeMargin ? 0 : { base: 4, md: 8 }}
        justifyContent={'start'}
      >
        <Flex>
          <Stack spacing="3" w={'100%'}>

            {/* Symptoms Data */}
            {symptoms && symptoms.length > 0 &&
              <>
                {name === "Medication Refill" ?
                <>
                  <Text fontSize={{ base: 'md', md: 'lg' }} pb={2}>
                    Get a Medication Refill FASTER! It's simple, just upload a picture of the label on your prescription bottle, answer a few questions, and one of our healthcare providers will send a prescription refill to your preferred pharmacy ASAP.
                  </Text>
                </>
                :
                <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'}>
                   {name} Symptoms
                </Text>
                }

                <Box fontSize={{ base: 'md', md: 'lg' }}>
                  {symptoms.map((section, index) => (
                    <Box key={index} mb={4}>
                      {symptoms.length > 1 && <Text fontWeight="bold">{section.sectionName}</Text>}
                      <UnorderedList pl={5}>
                        {section.sectionDetails.map((detail, detailIndex) => {
                          const splitDetail = detail.split(':');
                          return (
                            <ListItem key={detailIndex}>
                              {splitDetail.length > 1 ? (
                                <>
                                  <Text as="span" fontWeight="bold">
                                    {splitDetail[0]}:
                                  </Text>
                                  {`${splitDetail[1]}`}
                                </>
                              ) : (
                                <Text as="span">{detail}</Text>
                              )}
                            </ListItem>
                          );
                        })}
                      </UnorderedList>
                    </Box>
                  ))}
                </Box>
              </>
            }
            {/* END Symptoms Data */}
            {name === "Birth Control" &&
            <>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'}>
                {name}
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
                Use our Birth Control treatment protocol if you are looking to prevent pregnancy, reduce menstrual cramps and pain, make periods lighter and more regular, clear up acne, or lower the risk of certain types of cancer.
              </Text>
            </>
            }
            {name && name.includes('General Consult') && pathname !== '/general-consult' && pathname !== '/general-consult-pediatric' && pathname !== '/treatments/general-consult' &&
              <Text fontSize={{ base: 'md', md: 'lg' }} pb={2}>
                Our General Consult treatment protocol can be used for those issues you just can't identify on your own and would like to discuss with one of our healthcare providers.
              </Text>
            }
            {name && name.includes('General Consult') && pathname === '/general-consult' &&
              <Text fontSize={{ base: 'md', md: 'lg' }} pb={2}>
                This is a great opportunity to have a 1-on-1 discussion with one of our U.S.-licensed healthcare providers regarding any health-related concerns you may have. Our healthcare providers are here to help! And happy to design an appropriate treatment plan or provide recommendations to address your concerns.
              </Text>
            }
            
            {commonMedications &&
              <Flex w={'100%'} flexDirection={'column'} mt={1}>
                <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                  Commonly Prescribed Medications for {name}
                </Text>
                {name === "Weight Loss" ?
                  <Box fontSize={{ base: 'md', md: 'lg' }}>
                    <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight={'bold'} pb={3}>Semaglutide</Text>
                    <Text pb={3}>Semaglutide is a medication belonging to the class of glucagon-like peptide-1 (GLP-1) receptor agonists. It is used primarily for the treatment of type 2 diabetes mellitus (T2DM) and obesity. Semaglutide works by mimicking the action of naturally occurring GLP-1 hormones in the body, which play a crucial role in 	regulating blood sugar levels, appetite, and body weight.</Text>
                  </Box>
                  :
                  <Box fontSize={{ base: 'md', md: 'lg' }}>
                    <UnorderedList pl={5}>
                      {commonMedications.map((item,index) => (
                        <ListItem key={index}>
                          <Text as="span">{item}</Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                }
                {name !== 'Weight Loss' &&
                  <Button variant={'primaryBlueOutline'} size="lg" my={5} maxW={'350px'}
                    onClick={() => window.open('https://www1.singlecare.com/symliphyrx', '_blank')}
                    display={{base:'none'}}
                  >
                    Prescription Price Lookup
                  </Button>
                }
              </Flex>
            }

            <Flex w={'100%'} flexDirection={'column'} mt={4}>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                Risk Free To Get Started 
              </Text>

              {name && name.includes('Weight') ?
              <Text fontSize={{ base: 'md', md: 'lg' }} pt={1}>
                If the online healthcare questionnaire for Virtual or Live Consultations indicates that you do not qualify for treatment, you will NOT incur any charges.
              </Text>
              :
              <Text fontSize={{ base: 'md', md: 'lg' }} pt={1}>
                If the online healthcare questionnaire for Virtual or Live Consultations indicates that you do not qualify for treatment, you will NOT incur any charges.
              </Text>
              }
              
              <Button variant={'primaryBlueOutline'} size="lg" my={5} maxW={'350px'}
                onClick={() => window.open('/how-it-works', '_blank')}
              >
                How It Works
              </Button>
              
            </Flex>  

            {/* What You Need Data */}
            {whatYouNeed && whatYouNeed.length > 0 &&
              <Flex w={'100%'} flexDirection={'column'}>
                <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                  What You Need
                </Text>
                <Box fontSize={{ base: 'md', md: 'lg' }}>
                  <UnorderedList pl={5}>
                    {whatYouNeed.map((item,index) => (
                      <ListItem key={index}>
                        <Text as="span">{getLongDescription(item)}</Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Text fontSize={{ base: 'md', md: 'md' }} fontWeight={'bold'} color={'primaryOrange.default'} pt={3}>
                  Missing any of these items? Don't worry! Just bring what you have and your provider can you help with the rest.
                </Text>
                <Button variant={'primaryBlueOutline'} size="lg" my={5} maxW={'350px'}
                onClick={() => window.open('/faqs?section=weight-loss', '_blank')}
                >
                  Frequently Asked Questions
                </Button>
              </Flex>
            }
            {/* END What You Need Data */}
          </Stack>
        </Flex>
      </Flex>
    </>
  )
}