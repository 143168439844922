import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    Flex,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

export const MobileDrawer = ({ onClose, ...props }) => {
  const { isOpen: isFollowUpModalOpen, onOpen: onFollowUpModalOpen, onClose: onFollowUpModalClose } = useDisclosure();
  const { isOpen: isOpenPatientProfile, onOpen: onOpenPatientProfile, onClose: onClosePatientProfile } = useDisclosure(); // Patient Profile Modal
  
  const navigate = useNavigate();

  const { guid, pharmacy, source } = useUser();

  const handleCategoryClick = (item) => {

    console.log('item',item)

    // Pushing an event to the dataLayer on category click
    window.dataLayer.push({
      event: 'category_click',
      destination: item,
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });

    if (item === 'Medication Refill') {
      navigate('/medication-refill');
    } else if (item === 'General Consult') {
      navigate('/general-consult');
    } else if (item === 'How It Works') {
      navigate('/how-it-works');
    } else if (item === 'FAQs') {
      navigate('/faqs');
    } else if (item === 'All Services') {
      navigate('/care')
    } else if (item === 'Women\'s Health') {
      navigate('/category/womens-health')
    } else if (item === 'Men\'s Health') {
      navigate('/category/mens-health')
    } else if (item === 'Pediatric Health') {
      navigate('/category/pediatric-health')
    } else if (item === 'Urgent Care') {
      navigate('/category/urgent-care')
    } else if (item === 'Weight Loss') {
      navigate('/treatments/weight-loss');
    }
    onClose(); // Close the drawer
  };

  const handleMyAccountClick = () => {
    onOpenPatientProfile();
    onClose(); // Close the drawer
  };

  const handleExistingCaseModalClick = () => {
    onFollowUpModalOpen(); // Opens the modal
  };

  const handlePatientProfile = () => {

    // Pushing an event to the dataLayer on patient profile
    window.dataLayer.push({
      event: 'genie_patient_profile_click',
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });

    window.open('https://app.symliphy.com/zoom/rpm/#/101406/auth/login', '_blank');
    onClose();
  };

  const handleFindTreatmentClick = () => {

    // Pushing an event to the dataLayer on find treatment click
    window.dataLayer.push({
      event: 'find_treatment_click',
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });

    //navigate('/?show=treatments');
    navigate('/care')
    onFollowUpModalClose();
    onClosePatientProfile();
    onClose();
  };
  const handleStartGeneralConsultClick = () => {

    // Pushing an event to the dataLayer on find treatment click
    window.dataLayer.push({
      event: 'start_general_consult_click',
      guid: guid,
      pharmacy: pharmacy,
      source: source,
    });

    navigate('/general-consult');
    onFollowUpModalClose();
    onClosePatientProfile();
    onClose();
  };

  return (
    <>
      <Drawer placement="top" isOpen={props.isOpen} onClose={props.onClose} {...props}>
        <DrawerContent>
          <IconButton
            aria-label="Close menu"
            icon={<IoMdClose />}
            position="absolute"
            top="4"
            right="4"
            onClick={onClose}
          />
          <DrawerBody mt="12" mb="4">
            <Stack spacing="2" align="stretch">
              {["Weight Loss", "Urgent Care", "Women's Health", "Men's Health", "Pediatric Health", "Medication Refill", "General Consult", "How It Works", "FAQs", "All Services"]
                .filter(item => {
                  if (item === "Weight Loss" && pharmacy === "Giant Eagle") {
                    return false;
                  }
                  return true;
                })
                .map((item) => (
                  <Button key={item} size="md" variant="text" colorScheme="gray" onClick={() => {
                    console.log('Button clicked:', item);
                    handleCategoryClick(item);
                  }}>
                  {item}
                </Button>              
              ))}
              <Button size={'lg'} variant={'primaryBlueSolid'} onClick={handleMyAccountClick}>
                My Patient Profile
              </Button>
              <Button size={'lg'} variant={'primaryBlueOutline'} onClick={handleExistingCaseModalClick} whiteSpace={'normal'}>
                Provider Follow-Up
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Modal for "Speak to a provider..." */}
      <Modal isOpen={isFollowUpModalOpen} onClose={onFollowUpModalClose} size={{base:'full',md:"2xl"}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provider Follow-Up</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='bold' fontSize={'1.2em'}>Existing Patients:</Text>
            <Text py={3}>If you have an <b>active case</b> (submitted within the last 72 hours) and wish to follow-up with a provider on a previous consultation, please contact us at 925-460-9191 or email at <a href="mailto:customercare@geniemd.com">customercare@geniemid.com</a>.</Text>
            <Text py={3}>Alternatively, you can login to your patient profile by using the button below. Once in the portal, please click on the bell icon ( <FiBell style={{ display: 'inline', verticalAlign: 'text-bottom' }} /> ) at the top of the screen to review provider messages on your case.</Text>
            <Flex direction={{base:'column',md:'row'}} gap={3}>                     
              <Button variant="primaryOrangeOutline" mr={3} onClick={handlePatientProfile}>
                Visit Your Patient Portal
              </Button>
            </Flex>
            <Text fontWeight='bold' fontSize={'1.2em'} pt={7}>New Patient?</Text>
            <Text py={3}>Welcome! Please
              pick a treatment to get started, or if
              you're unsure, start a general
              consultation to work with one of our
              healthcare providers.</Text>
            <Flex direction={{base:'column',md:'row'}} gap={3}>
              <Button variant="primaryBlueSolid" mr={3} onClick={handleFindTreatmentClick}>
                Find Treatment
              </Button>
              <Button variant="primaryBlueOutline" mr={3} onClick={handleStartGeneralConsultClick}>
                Start General Consultation
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onFollowUpModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for "Patient Profile" */}
      <Modal isOpen={isOpenPatientProfile} onClose={onClosePatientProfile} size={{base:'full',md:"2xl"}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>My Patient Portal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='bold' fontSize={'1.2em'}>Existing Patients:</Text>
            <Text py={3}>
              To access your patient profile information or to see messages from your provider on an <b>active case</b>(submitted within the last 72 hours), please login using the button below. Once in the portal, please click on the bell icon ( <FiBell style={{ display: 'inline', verticalAlign: 'text-bottom' }} /> ) at the top of the screen to review provider messages on your case.
            </Text>
            <Flex gap={3} flexDirection={{base:'column', md:'row'}}>
              <Button variant="primaryOrangeSolid" mr={3} onClick={handlePatientProfile}>
                Visit Your Patient Portal
              </Button>
            </Flex>
            <Text fontWeight='bold' fontSize={'1.2em'} pt={7}>New Patient?</Text>
            <Text py={3}>Welcome! Please
              pick a treatment to get started, or if
              you're unsure, start a general
              consultation to work with one of our
              healthcare providers.</Text>
            <Flex gap={3} flexDirection={{base:'column', md:'row'}}>
              <Button variant="primaryBlueSolid" mr={3} onClick={handleFindTreatmentClick}>
                Find Treatment
              </Button>
              <Button variant="primaryBlueOutline" mr={3} onClick={handleStartGeneralConsultClick}>
                Start General Consultation
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClosePatientProfile}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
