import {
  Alert, AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FiEdit } from "react-icons/fi";
import { useLocation } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { AllStates, AsyncStates, WeightLossStates } from '../data/_states.ts';
import { treatmentData } from '../data/_treatments.ts';
import { sendCallback } from '../helpers/fetch.js';
import { FAQsBlock } from './FAQsBlock';
import { TreatmentDetails } from './TreatmentDetails.jsx';

export const ActionBar = ({ priceRangeParam, protocolNameParam, treatmentCategory, treatmentList, selectedTreatment, onProtocolNameChange }) => {

  const { pathname } = useLocation();

  const { guid, pharmacy, source } = useUser();
  //console.log(pathname)

  //console.log("selectedTreatment",selectedTreatment)

  const initialValues = {
    stateLocation: '',
    protocolName: selectedTreatment || ''
  };

  const [formState, setFormState] = useState(initialValues);
  const [asyncAllowed, setAsyncAllowed] = useState(false);
  const [priceRange, setPriceRange] = useState(priceRangeParam || null);
  const [protocolName, setProtocolName] = useState(protocolNameParam || selectedTreatment || null);
  const [genieMdName, setGenieMdName] = useState(null);
  const [treatmentObject, setTreatmentObject] = useState(null);
  const [pharmacyKey] = useState(localStorage.getItem('pharmacy'));
  const [selectedMode, setSelectedMode] = useState(null);

  console.log("pharmacyKey", pharmacyKey)

  useEffect(() => {
    setAsyncAllowed(AsyncStates.includes(formState.stateLocation));
    if (pathname && (pathname.includes('general-consult') || pathname.includes('weight'))) {
      setAsyncAllowed(false);
    }
  }, [formState.stateLocation, pathname]);

  //console.log("protocolNameParam",protocolNameParam,"protocolName",protocolName,"pathname",pathname,"formState",formState,"asyncAllowed",asyncAllowed);
 
  // Update priceRange based on protocolName
  useEffect(() => {
    if (protocolNameParam) {
      const matchingTreatment = treatmentData.find(treatment => treatment.name.toLowerCase() === protocolNameParam.toLowerCase());
      if (matchingTreatment) {
        setTreatmentObject(matchingTreatment);
        setPriceRange(matchingTreatment.priceRange);
        setProtocolName(matchingTreatment.name);
        setGenieMdName(matchingTreatment.genieMdName);
      } else {
        setPriceRange('');
        setProtocolName('');
        setGenieMdName('');
      }
    } else if (protocolName) {
      const matchingTreatment = treatmentData.find(treatment => treatment.name.toLowerCase() === protocolName.toLowerCase());
      if (matchingTreatment) {
        setTreatmentObject(matchingTreatment);
        setPriceRange(matchingTreatment.priceRange);
        setProtocolName(matchingTreatment.name);
        setGenieMdName(matchingTreatment.genieMdName);
      } else {
        setPriceRange('');
        setProtocolName('');
        setGenieMdName('');
      }
    } else if (pathname.includes('treatment')) {
      const pathSegments = pathname.split('/');
      // Assuming the category name is always after '/category/' or '/categories/'
      const treatmentNameFromUrl = pathSegments.find(segment => segment === 'treatment' || segment === 'treatments') ? pathSegments[pathSegments.indexOf('treatment') + 1] || pathSegments[pathSegments.indexOf('treatments') + 1] : null;
      const matchingTreatment = treatmentData.find(treatment => treatment.path === treatmentNameFromUrl);
      if (matchingTreatment) {
        setTreatmentObject(matchingTreatment);
        setPriceRange(matchingTreatment.priceRange);
        setProtocolName(matchingTreatment.name);
        setGenieMdName(matchingTreatment.genieMdName);
      } else {
        setPriceRange('');
        setProtocolName('');
        setGenieMdName('');
      }
    } else if (pathname && pathname.includes('general-consult')) {
      setTreatmentObject(treatmentData.find(treatment => treatment.name.toLowerCase() === 'general consult'));
      setGenieMdName('General Consult');
    } else if (pathname && pathname.includes('medication-refill')) {
      setTreatmentObject(treatmentData.find(treatment => treatment.name.toLowerCase() === 'medication refill'));
      setGenieMdName('Medication Refill');
    } 

  }, [protocolName, pathname, protocolNameParam]);

  const handleSubmit = (values) => {
    //console.log("Selected State:", values.stateLocation);
    const transactionId = localStorage.getItem('transaction_id');
    console.log('Sending Callback to API', transactionId);
    sendCallback(transactionId);
  
    // Delete the localStorage item
    localStorage.removeItem('transaction_id');
  };
  

  // Modal Handling
  const initialModalState = {
    modal1: false, // Medication Price Lookup
    modal2: false, // Sync vs. Async
    modal3: false,  // FAQs
    modal4: false, // Pediatric
    modal5: false // Weight Loss
  };
  const [modalState, setModalState] = useState(initialModalState);
  const [attemptedNavigation, setAttemptedNavigation] = useState(false);
  const [weightLossType, setWeightLossType] = useState('');


  const openModal = (modalId) => {
    setModalState(prevState => ({ ...prevState, [modalId]: true }));
  };
  const closeModal = (modalId, callback) => {
    setModalState(prevState => ({ ...prevState, [modalId]: false }));
    if(callback) {
      callback();
    }
  };  
  // END Modal Handling

  const onlinePrice = 29;
  const videoPrice = 74;

  console.log("pharmacy",localStorage.getItem("pharmacy"))

  const constructUrl = (mode, state, price, clientId='101365', genieMdNameParam) => {
    console.log('constructing URL',mode, state, price, clientId, genieMdNameParam)
    const baseUrl = "https://app.symliphy.com";
    //const condition = encodeURIComponent(protocolName);
    const pharmacyId = localStorage.getItem("pharmacyId") || '';
    const groupPharmacyId = localStorage.getItem("pharmacy") || '';
    const guid = localStorage.getItem("guid") || '';
    //const sourceId = localStorage.getItem("campaign") || localStorage.getItem("pharmacy") || '';
    const campaign = localStorage.getItem("campaign") || '';
    const source = localStorage.getItem("source") || '';
    const pharmacy = localStorage.getItem("pharmacy") || '';

    let sourceId;
    if (campaign && source) {
      sourceId = `${campaign} - ${source}`;
    } else if (campaign) {
      sourceId = campaign;
    } else if (source) {
      sourceId = source;
    } else {
      sourceId = pharmacy;
    }
    console.log(sourceId);

    if (genieMdNameParam && genieMdNameParam.includes('Weight')) {
      clientId='101406'
    } else {
      clientId='101365'
    }

    const encodedAmount = btoa(String.fromCharCode(...new TextEncoder().encode(String(price))));
    //const encodedAmount = price;
    const encodedState = encodeURIComponent(state);
  
    //let url = `${baseUrl}/apps/assessment/#/protocol/${clientId}/consent?protocolName=${condition}&fee=${amount}&theme=default&mode=${mode}&state=${encodedState}&login=true`;

    let url = `${baseUrl}/zoom/assessment/#/protocol/${clientId}/consent?protocolName=${genieMdNameParam}&theme=default&mode=${mode}&state=${encodedState}&login=true&fee=${encodedAmount}&patientOEMID=970`;
    console.log('url',url)
  
    if (pharmacyId) url += `&pharmacyID=${pharmacyId}`;
    if (groupPharmacyId) url += `&pharmacyGroupID=${groupPharmacyId}`;
    if (guid) url += `&advertisingID=${guid}`;
    if (sourceId) url += `&sourceID=${sourceId}`;
    if (genieMdNameParam && genieMdNameParam.includes('Weight')) {
      url += `&protocolClinicID=101404`
    }/* else {
      url += `&protocolClinicID=101365`
    };*/
  
    return url;
  };
  
  const handleButtonClick = (mode) => {
    setSelectedMode(mode);
    console.log('genieMdName',genieMdName)
    const genieMdNameParam = encodeURIComponent(genieMdName);
    console.log('genieMdNameParam',genieMdNameParam)
    if (protocolName && protocolName.includes('Pediatric')) {
      openModal('modal4');
      // Set the flag to true to indicate a navigation attempt
      setAttemptedNavigation(true);
    } else {
      // If no modal is needed, proceed as usual
      const state = formState.stateLocation;
      const url = constructUrl(mode, state, mode === 1 ? onlinePrice : videoPrice, '101406', genieMdNameParam);

      // Pushing an event to the dataLayer on clickthrough
      window.dataLayer.push({
        event: 'conversion_to_genie',
        guid: guid,
        pharmacy: pharmacy,
        source: source,
        protocol: genieMdName,
        mode: mode === 1 ? 'async' : 'sync',
        stateLocation: state,
      });
      console.log('state',state)

      window.open(url, '_blank');
    }
  };

  const handleWeightLoss = (type, confirmed) => {
    setSelectedMode(2);
    setWeightLossType(type);
    const genieMdNameParam = type==="New" ? "Weight Loss Initial Visit" : "Weight Loss Follow up";

    if (!confirmed) {
      openModal('modal5');
      return;
    }

    // If no modal is needed, proceed as usual
    const state = formState.stateLocation;
    const url = constructUrl(2, state, type==="New" ? 349 : 349, '101406', genieMdNameParam);

    // Pushing an event to the dataLayer on clickthrough
    window.dataLayer.push({
      event: 'conversion_to_genie',
      guid: guid,
      pharmacy: pharmacy,
      source: source,
      protocol: genieMdName,
      mode: type==="New" ? 'new' : 'existing',
      stateLocation: state,
    });
    console.log('state',state)

    window.open(url, '_blank');
  };  

  const handleModalClose = (modalId) => {
    closeModal(modalId); // Existing close logic
    if (modalId === 'modal4' && attemptedNavigation) {
      // Reset the flag
      setAttemptedNavigation(false);
      // Proceed with the navigation that was attempted earlier
      const state = initialValues.stateLocation;
      const genieMdNameParam = encodeURIComponent(genieMdName);
      const url = constructUrl(selectedMode, state, selectedMode === 1 ? onlinePrice : videoPrice, genieMdNameParam);

      // Pushing an event to the dataLayer on clickthrough
      window.dataLayer.push({
        event: 'conversion_to_genie_pediatric',
        guid: guid,
        pharmacy: pharmacy,
        source: source,
        protocol: genieMdName,
        mode: selectedMode === 1 ? 'async' : 'sync',
        stateLocation: state,
      });

      window.open(url, '_blank');
    }
  };
  
  
  return (
    <>
      <Box
        as="section"
        pt={{ base: 0, md: 6 }}
        pb={{ base: 6, md: 6 }}
        justifyContent={'center'}
        position="sticky" top="50px"
        w={'100%'} h={'fit-content'} 
        borderWidth={{base:0,md:"1px"}}
        mr={{base:0, md:pathname.includes('categor') ? 0:8, "2xl":0}} my={{base: 6, md: 12}} 
        boxShadow={{base: 'none', md: pathname.includes('categor') ? 'none' : 'symShadow'}}
        borderColor={{base:'lightGray.lighten70', md:pathname.includes('categor') ? 'white' : 'lightGray.lighten70'}}
        height={pathname.includes('categor') ? "100%" : "fit-content"}
      >
        <Flex flexDirection="column" w="100%" gap={3} px={{ base: 4, md: 8 }} 
          borderTop={{base:'none',md:pathname.includes('categor') ? 'none' : 'solid 6px #F26739'}} mt={-6} pt={6}
          maxH={ pathname.includes('weight') ? 'calc(100vh - 100px)' : '100%'}
          overflowY={'scroll'} overflowX={'hidden'}
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px', // Adjust the width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#DDD', // Scrollbar thumb color
              borderRadius: '8px', // Rounded edges for the scrollbar thumb
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#777', // Scrollbar thumb color on hover
            },
          }}
        >

          <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="700" color="accent" display={{base:"none",md:"block"}}          >
            Get Started Here
          </Text>


          {((pathname.includes('categor') && selectedTreatment) || pathname.includes('treatment') || pathname === ('/general-consult') || pathname.includes('refill')) &&
          <Formik
            initialValues={{ stateLocation: '', protocolName: selectedTreatment }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ handleChange, handleBlur, values, setFieldValue }) => {
              const handleStateChange = (e) => {
                const state = e.target.value;
                setFormState({ ...values, stateLocation: state });
                handleChange(e);
              };

              const handleProtocolChange = (e) => {
                const { name, value } = e.target;
                if (name === "protocolName") {
                  setProtocolName(value);
                  onProtocolNameChange(e.target.value);
              
                  // If the selected value is the blank option, reset priceRange to an empty string
                  if (value === "") {
                    setPriceRange("");
                  } else {
                    // Otherwise, find the treatment in treatmentData by protocolName and set priceRange accordingly
                    const matchingTreatment = treatmentData.find(treatment => treatment.genieMdName === value);
                    if (matchingTreatment) {
                      setPriceRange(matchingTreatment.priceRange);
                    } else {
                      // If there's no matching treatment, also reset priceRange
                      setPriceRange("");
                    }
                  }
                }
                // Update form state for other fields
                setFormState(prev => ({ ...prev, [name]: value }));
              };

              return (
                <Form>
                  {!protocolNameParam && pathname.includes('categor') &&
                    <Box display={{base:"none", md:"block"}} pb={4} mt={2}>
                      <Text mb={2} fontWeight={'bold'}>Choose Your Condition</Text>
                      <Field as={Select} name="protocolName" size="lg" 
                        onChange={handleProtocolChange} onBlur={handleBlur} value={values.protocolName}
                        default={selectedTreatment}
                      >
                        <option value="" disabled>Choose treatment</option>
                        {/* Dynamically generate option elements from the treatmentList */}
                        {treatmentList.map((treatment, index) => (
                          <option key={index} value={treatment}>{treatment}</option>
                        ))}
                      </Field>
                    </Box>
                  }
                  <Box display={{base:"none",md:"block"}}>
                    <Field as={Select} name="stateLocation" placeholder="What state do you live in?" size="lg"
                      onChange={handleStateChange} onBlur={handleBlur} value={values.stateLocation}
                      bgColor='white'
                      style={{
                        boxShadow: values.stateLocation ? 'none' : '0px 0px 10px rgba(66, 153, 225, 0.6)',
                        transition: 'box-shadow 0.3s ease-in-out'
                      }}
                      className={values.stateLocation === "" ? "pulseShadow" : ""}
                    >
                      {(protocolName === 'Weight Loss' ? WeightLossStates : AllStates).map((state) => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </Field>
                  </Box>
                  <Box>
                      <Flex flexDirection={'column'} gap={3}>
                        {values.stateLocation &&
                          <>
                          {!pathname.includes('weight') ?
                          <Box display={{base:"none",md:"block"}}>
                            {asyncAllowed &&
                              <Button type="submit" variant="primaryYellowSolid" size="lg" mt={2} w={'100%'}
                                onClick={() => handleButtonClick(1)}
                              >
                                Virtual Consultation - ${onlinePrice}
                              </Button>
                            }
                            {((protocolName && protocolName==="Medication Refill") || (pathname === '/medication-refill')) && asyncAllowed ?
                            <></>
                            :
                            <Button type="submit" variant="primaryYellowSolid" size="lg" mt={asyncAllowed ? 2 : 2} w={'100%'} 
                              onClick={() => handleButtonClick(2)}
                            >
                              Live Consultation - ${videoPrice}
                            </Button>
                            }
                            {protocolName !== "Medication Refill" && asyncAllowed ?
                              <Link color={"primaryBlue.default"} fontSize={'sm'}
                                width={'fit-content'}
                                onClick={() => openModal('modal2')}
                              >
                                What's the difference?
                              </Link>
                              :
                              <Link color={"primaryBlue.default"} fontSize={'sm'}
                                width={'fit-content'}
                                onClick={() => openModal('modal2')}
                              >
                                How does this work?
                              </Link>
                            }
                          </Box>
                          :
                          <Box display={{base:"none",md:"block"}}>
                            <Button type="submit" variant="primaryYellowSolid" size="lg" mt={2} h={'70px'} w={'100%'} whiteSpace="normal"
                              onClick={() => handleWeightLoss("New", false)}
                            >
                              New Weight Loss Patient Consultation
                            </Button>
                            <Button type="submit" variant="primaryYellowSolid" size="lg" mt={2} h={'70px'} w={'100%'} whiteSpace="normal"
                              onClick={() => handleWeightLoss("Existing", false)}
                            >
                              Existing Weight Loss Patient Consultation
                            </Button>   
                            {protocolName && protocolName.includes('Weight') &&
                              <Box mt={1}>
                                <Link color={"primaryBlue.default"} fontSize={'sm'}
                                  width={'fit-content'}
                                  onClick={() => openModal('modal2')}
                                >
                                  Which one do I pick?
                                </Link>
                              </Box>
                            }                   
                          </Box>
                          }
                          </>
                        }
                        <Divider mt={values.stateLocation ? 4 : 8} mb={3} 
                          display={{base:pathname.includes('categor') ? 'none' : 'block', md:'block'}}
                        />
                        {pathname.includes('categor') && treatmentObject &&
                        <Box display={{base:'block',md:'none'}}>
                          <TreatmentDetails 
                            name={treatmentObject.name}
                            symptoms={treatmentObject.symptoms}
                            whatYouNeed={treatmentObject.whatYouNeed}
                            commonMedications={treatmentObject.commonMedications}
                            removeMargin={true}
                          />
                        </Box>
                        }
                        {!pathname.includes('weight') ?
                          <>
                            <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="700" color="accent"
                              mt={{base:pathname.includes('categor') ? 4 : 0, md: 0}}
                            >
                              How Much Will It Cost?
                            </Text>
                            <Text>
                              Your total cost for treatment will be comprised of the fee for the consultation type selected and the prescription fee at your local pharmacy. 
                            </Text>
                            <Text color={'primaryBlue.default'}>
                              Consultation Fee + Prescription Fee = Total Treatment Cost
                            </Text>
                            {priceRange && priceRange !== 'Varies' ?
                              <>
                                <Text>
                                  Common medications prescribed for this condition range from <b>{priceRange && priceRange}</b> when using our Symliphy Rx savings card.*
                                </Text>
                                <Button variant={'primaryBlueSolid'} size="xl" py={4} px={6} mt={1} w={'100%'}
                                  onClick={() => {window.open('/savings','_blank')}}
                                >
                                  Get Symliphy Savings Card
                                </Button>
                                <Text>
                                  *Prices may vary. Some medications prescribed may fall outside of the range listed.
                                </Text>
                              </>
                              :
                              <>
                                <Text>
                                  The Prescription Fees for common medications prescribed for this condition vary, therefore we cannot provide an accurate price range. Please use the Symliphy Rx Savings Card to save up to 80% on your prescription.
                                </Text>
                                <Button variant={'primaryBlueSolid'} size="lg" py={4} px={6} mt={1} w={'100%'}
                                  onClick={() => {window.open('/savings','_blank')}}
                                >
                                  Get Symliphy Savings Card
                                </Button>
                              </>
                            }
                            {pharmacyKey !== 'Giant Eagle' &&
                              <Button variant={'primaryBlueOutline'} py={8} mt={2} w={'100%'} size="lg"
                                onClick={() => window.open('https://www1.singlecare.com/symliphyrx')}
                              >
                                Look Up Common 
                                <br/>Medications and Prices
                              </Button>
                            }
                          </>
                          :
                          <>
                            <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="700" color="accent"
                              mt={{base:pathname.includes('categor') ? 4 : 0, md: 0}}
                            >
                              How Much Will It Cost?
                            </Text>
                            <Text>
                              The Symliphy Weight Loss Consultation fees include the healthcare provider consultation, medications, Quest lab test data integration, shipping, and handling.
                            </Text>
                            <Text fontWeight="700" mt={2} textDecoration={'underline'}>New Patients</Text>
                            <Text>
                              Your total cost of treatment will be comprised of the Symliphy Weight Loss Consultation fee and the lab test fee at your local Quest Diagnostics facility.
                            </Text>
                            <Text>
                            The Quest Diagnostics lab test fees typically range from $60 to $80. You must pay Quest directly for the lab test via cash or insurance.
                            </Text>
                            <Text fontWeight="700" mt={2} textDecoration={'underline'}>Existing Patients</Text>
                            <Text>
                              Your total cost of treatment will be comprised of the Symliphy Weight Loss Consultation fee only.
                            </Text>
                            <Text>
                              The Quest Diagnostics lab fees are typically not applicable on
                              Existing Weight Loss Patient Consultations. If additional lab
                              work is required, your Symliphy healthcare provider will inform
                              you as such and send a new lab order to Quest Diagnostics.
                            </Text>
                          </>
                        }
                      </Flex>
                    
                  </Box>
                </Form>
            )}}
          </Formik>}

          {/* Modal1 */}
          <Modal isOpen={modalState.modal1} onClose={() => closeModal('modal1')} size={'6xl'}>
            <ModalOverlay />
            <ModalContent h={'80vh'}>
              <ModalHeader>Medications and Prices</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box as="iframe" src="https://www1.singlecare.com/symliphyrx" w={'100%'} h={'100%'} />
              </ModalBody>
              <ModalFooter>
                <Button variant={"primaryBlueOutline"} onClick={() => closeModal('modal1')}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Modal2 */}
          <Modal isOpen={modalState.modal2} onClose={() => closeModal('modal2')} size={{base:'full', md:'2xl'}}>
            <ModalOverlay />
            <ModalContent h={'auto'}>
              <ModalHeader>How It Works</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {protocolName && protocolName.includes('Weight') ?
                  <Stack gap="3">
                    <Text pb={5}>
                      Select <b>New Patient</b> if this is your first time
                      receiving weight loss treatment through
                      Symliphy – even if you have tried weight
                      loss treatments from other providers. Our
                      healthcare providers will work with you to
                      determine the best approach for your
                      weight loss goals.
                    </Text>
                    <Text pb={5}>
                      Select <b>Existing Patient</b> if you are a
                      returning Symliphy weight loss patient.
                      Your provider will check in on your
                      progress and submit a medication refill (if
                      appropriate).
                    </Text>
                  </Stack>
                :
                  <Stack gap="3">
                    {asyncAllowed &&
                      <>
                        <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="700">Virtual Consultation</Text>
                        <Text pb={5}>
                          You complete an online questionnaire, and one of our healthcare providers will review it and get back to you quickly with recommended treatment options, including a prescription if appropriate.
                        </Text>
                      </>
                    }
                    { ((((protocolName==="Medication Refill") || (pathname === '/medication-refill')) && asyncAllowed===false) || ((protocolName !=="Medication Refill") && (pathname !== '/medication-refill'))) &&
                    <>
                      <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="700">Live Consultation</Text>
                      <Text pb={5}>
                        You complete an online questionnaire, one of our healthcare providers will review it, and contact you directly via phone or video conference to personally discuss your concerns and recommended treatment options, including a prescription if appropriate. 
                      </Text>
                    </>
                    }
                    {asyncAllowed && protocolName !== 'Medication Refill' && pathname !== '/medication-refill' && 
                    <>
                      <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="700">Which One Should I Pick?</Text>
                      <Text pb={5}>
                        The choice is yours! Depending on the state you live, certain treatments may require a Live Consultation. If the Virtual Consultation option is not available, then please choose Live Consultation to continue.
                      </Text>
                    </>
                    }
                    {priceRange &&
                    <>
                      <Text>
                        Your total cost for treatment will be comprised of the fee for the consultation type selected and the prescription fee at your local pharmacy. 
                      </Text>
                      <Text color={'primaryBlue.default'}>
                        Consultation Fee + Prescription Fee = Total Treatment Cost
                      </Text>
                      <Text>
                        Common medications prescribed for this condition range from <b>{priceRange && priceRange}</b> when using our Symliphy Rx savings card.*
                      </Text>
                      <Button variant={'primaryBlueSolid'} size="xl" py={4} px={6} mt={4} w={'100%'}
                        onClick={() => {window.open('/savings','_blank')}}
                      >
                        Download Savings Card
                      </Button>
                      <Text>
                        *Prices may vary. Some medications prescribed may fall outside of the range listed.
                      </Text>
                    </>
                    }
                  </Stack>
                }
              </ModalBody>
              <ModalFooter>
                <Button variant={"primaryYellowSolid"} onClick={() => closeModal('modal2')}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Modal3 */}
          <Modal isOpen={modalState.modal3} onClose={() => closeModal('modal3')} size={'6xl'}>
            <ModalOverlay />
            <ModalContent h={'auto'}>
              <ModalHeader>How It Works</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FAQsBlock 
                  titleColor={"primaryOrange.default"}
                />
              </ModalBody>
              <ModalFooter>
                <Button variant={"primaryOrangeOutline"} onClick={() => closeModal('modal3')}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Modal4 */}
          <Modal isOpen={modalState.modal4} onClose={() => closeModal('modal4')} size={'2xl'}>
            <ModalOverlay />
            <ModalContent h={'auto'}>
              <ModalHeader>Pediatric Treatments</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Alert fontSize={'1.2em'} mb={5}><AlertIcon />You have selected a Pediatric treatment for your child.</Alert>
                <Text fontSize={'1.2em'} mb={4}>
                  <b>Note for Parents</b> - During intake, please complete the New Patient Information for your child, but use your own personal contact information. Our healthcare providers may need to reach you regarding your child's case.
                </Text>
              </ModalBody>
              <ModalFooter mb={2}>
                <Button variant={"primaryOrangeSolid"} onClick={() => handleModalClose('modal4')}>
                  Continue to Care
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Modal5 */}
          <Modal isOpen={modalState.modal5} onClose={() => closeModal('modal5')} size={'2xl'}>
            <ModalOverlay />
            <ModalContent h={'auto'}>
              <ModalHeader>Weight Loss Treatments</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Alert fontSize={'1.2em'} mb={5}><AlertIcon />Confirm {weightLossType} Symliphy Weight Loss Protocol</Alert>
                <Text fontSize={'1.2em'}>
                  <b>Please make sure you select the correct option.</b> Depending on your selection, your healthcare provider will be able to provide the appropriate care.
                </Text>
              </ModalBody>
              <ModalFooter mb={2}>
                <Flex direction={'column'} gap={2} alignItems={'center'} w={'100%'}>
                <Button
                  size={'lg'}
                  variant={"primaryOrangeSolid"}
                  onClick={() => { handleWeightLoss(weightLossType, true); }}
                  sx={{
                    whiteSpace: 'normal',
                    maxWidth: '100%',
                    lineHeight: '1.5',
                    padding: ['2.5rem 1rem', null, '1rem']
                  }}
                >
                    Yes, I confirm that I'm {weightLossType==='New' ? 'a':'an'} {weightLossType.toLowerCase()} Symliphy weight loss customer
                  </Button>
                  <Button
                    size={'lg'}
                    variant={"primaryOrangeOutline"}
                    onClick={() => { handleWeightLoss(weightLossType === 'New' ? 'Existing' : 'New', true); }}
                    sx={{
                      whiteSpace: 'normal',
                      maxWidth: '100%',
                      lineHeight: '1.5',
                      padding: ['2.5rem 1rem', null, '1rem']
                    }}
                  >
                    Actually, I am {weightLossType==='New' ? 'an':'a'} {weightLossType==='New' ? 'existing' : 'new'} Symliphy weight loss customer
                  </Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      </Box>

      {/* MOBILE Fixed Bottom Action Bar */}
      <Flex position={'fixed'} bottom={pathname.includes('categor') ? 0 : '70px'} h={pathname.includes('categor') ? '230px' : '140px'} w={'100vw'} zIndex={100} bgColor={'primaryYellow.default'}
        display={{ base: "block", md: "none" }} boxShadow={'0 5px 15px black'}
      >
        <Formik initialValues={formState} onSubmit={handleSubmit} enableReinitialize>
          {({ handleChange, handleBlur, values, setFieldValue }) => {
            const handleStateChange = (e) => {
              const state = e.target.value;
              setFormState({ ...values, stateLocation: state });
              handleChange(e);
            };

            const handleProtocolChange = (e) => {
              const { name, value } = e.target;
              //console.log('name',name, value)
              if (name === "protocolName") {
                setProtocolName(value);
                onProtocolNameChange(e.target.value);
                // If the selected value is the blank option, reset priceRange to an empty string
                if (value === "") {
                  setPriceRange("");
                } else {
                  // Otherwise, find the treatment in treatmentData by protocolName and set priceRange accordingly
                  const matchingTreatment = treatmentData.find(treatment => treatment.genieMdName === value);
                  //console.log('matchingTreatment 2',matchingTreatment)
                  if (matchingTreatment) {
                    setPriceRange(matchingTreatment.priceRange);
                  } else {
                    // If there's no matching treatment, also reset priceRange
                    setPriceRange("");
                  }
                }
              }
              // Update form state for other fields
              setFormState(prev => ({ ...prev, [name]: value }));
            };

            return (
              <Form>
                <Flex m={3} w={'calc(100vw - 1.5rem)'} align="center" flexDirection={'column'} gap={0}>
                  {!protocolNameParam && pathname.includes('categor') &&
                    <Box w={'100%'} mb={3}>
                      <Text mb={2} fontWeight={'bold'}>Choose Your Condition</Text>
                      <Field as={Select} name="protocolName" placeholder="Choose treatment" size="lg" 
                        onChange={handleProtocolChange} onBlur={handleBlur} value={values.protocolName}
                        bgColor={'white'}
                      >
                        {/* Dynamically generate option elements from the treatmentList */}
                        {treatmentList.map((treatment, index) => (
                          <option key={index} value={treatment}>{treatment}</option>
                        ))}
                      </Field>
                    </Box>
                  }
                  <Box display={values.stateLocation ? 'none' : 'block'} w={'100%'} mt={{base:0,md:3}}>
                    <Text mb={2} fontWeight={'bold'}>Get Started Here</Text>
                    
                    <Field
                      as={Select} 
                      name="stateLocation" 
                      placeholder="What state do you live in?" 
                      size="lg"
                      bgColor="white"
                      onChange={handleStateChange} 
                      onBlur={handleBlur} 
                      value={values.stateLocation}
                      style={{
                        boxShadow: values.stateLocation ? 'none' : '0px 0px 10px rgba(66, 153, 225, 0.6)',
                        transition: 'box-shadow 0.3s ease-in-out'
                      }}
                      className={values.stateLocation === "" ? "pulseShadow" : ""}
                    >
                      {(protocolName === 'Weight Loss' ? WeightLossStates : AllStates).map((state) => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </Field>
                  </Box>

                  {pathname.includes('weight') && values.stateLocation ? (
                  <>
                    <Flex flexDirection={'column'} w={'100%'}>
                      <Text fontWeight={'bold'} mb={2}>Pick your Symliphy Weight Loss Journey</Text>
                      <Flex flex={1} justifyContent="space-around" gap={3} pb={2} mt={{base:pathname.includes('categor') ? "0px" : 0,md:0}}>
                        <Button type="submit" color="ghost" size="md" height="60px" 
                          w={'50%'} 
                          px={4}
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                          onClick={() => handleWeightLoss('New', false)}
                        >
                          New Patient
                        </Button>
                        <Button type="submit" mt={{base:0,md:"10px"}} color="ghost" size="md" height="60px" px={4} w={'50%'} 
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                          onClick={() => handleWeightLoss('Existing', false)}
                        >
                          Existing Patient
                        </Button>
                      </Flex>
                      <Flex justifyContent={'space-between'}>
                        {asyncAllowed && ((protocolName && protocolName!=="Medication Refill") && (pathname !== '/medication-refill')) ?
                          <Link color={"primaryBlue.default"} fontSize={'sm'}
                            width={'fit-content'}
                            onClick={() => openModal('modal2')}
                          >
                            What's the difference?
                          </Link>
                          : protocolName && protocolName.includes('Weight') ?
                          <Link color={"primaryBlue.default"} fontSize={'sm'}
                            width={'fit-content'}
                            onClick={() => openModal('modal2')}
                          >
                            Which one do I pick?
                          </Link>
                          :
                          <Link color={"primaryBlue.default"} fontSize={'sm'}
                            width={'fit-content'}
                            onClick={() => openModal('modal2')}
                          >
                            How does this work?
                          </Link>
                        }
                        <Flex gap={1} onClick={() => setFieldValue('stateLocation', '', true)}
                          alignItems={'top'}
                        >                  
                          <Link color={"primaryBlue.default"} fontSize={'sm'}
                            width={'fit-content'}
                            
                          >
                            {values.stateLocation && values.stateLocation} 
                          </Link>
                          <Box mt={'1px'}>
                            <FiEdit color={"#006699"} size={'14px'}/>
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  </> ) : (
                  <>
                    {((pathname.includes('categor') && values.stateLocation && values.protocolName) || (values.stateLocation)) && (
                      <Flex flexDirection={'column'} w={'100%'}>
                        <Text fontWeight={'bold'} mb={2}>Choose Your Consultation Type</Text>
                        <Flex flex={1} justifyContent="space-around" gap={3} pb={2} mt={{base:pathname.includes('categor') ? "0px" : 0,md:0}}>
                          {asyncAllowed && (
                            <Button type="submit" color="ghost" size="md" height="60px" 
                            w={((protocolName && protocolName==="Medication Refill") || (pathname === '/medication-refill')) ? '100%':'50%'} 
                            px={4}
                              style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                              onClick={() => handleButtonClick(1)}
                            >
                              Virtual Consult<br/>${onlinePrice}
                            </Button>
                          )}
                          {((protocolName && protocolName==="Medication Refill") || (pathname === '/medication-refill')) && asyncAllowed ?
                          <></>
                          :
                          <Button type="submit" mt={{base:0,md:"10px"}} color="ghost" size="md" height="60px" w={asyncAllowed ? '50%' : '100%'} px={4}
                            style={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                            onClick={() => handleButtonClick(2)}
                          >
                            Live Consult<br/>${videoPrice}
                          </Button>
                          }
                        </Flex>
                        <Flex justifyContent={'space-between'}>
                          {asyncAllowed && ((protocolName && protocolName!=="Medication Refill") && (pathname !== '/medication-refill'))?
                            <Link color={"primaryBlue.default"} fontSize={'sm'}
                              width={'fit-content'}
                              onClick={() => openModal('modal2')}
                            >
                              What's the difference?
                            </Link>
                            : protocolName && protocolName.includes('Weight') ?
                            <Link color={"primaryBlue.default"} fontSize={'sm'}
                              width={'fit-content'}
                              onClick={() => openModal('modal2')}
                            >
                              Which one do I pick?
                            </Link>
                            :
                            <Link color={"primaryBlue.default"} fontSize={'sm'}
                              width={'fit-content'}
                              onClick={() => openModal('modal2')}
                            >
                              How does this work?
                            </Link>
                          }
                          <Flex gap={1} onClick={() => setFieldValue('stateLocation', '', true)}
                            alignItems={'top'}
                          >                  
                            <Link color={"primaryBlue.default"} fontSize={'sm'}
                              width={'fit-content'}
                              
                            >
                              {values.stateLocation && values.stateLocation} 
                            </Link>
                            <Box mt={'1px'}>
                              <FiEdit color={"#006699"} size={'14px'}/>
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}                  
                  </>
                  )}
                </Flex>
              </Form>
          )}}
        </Formik>
      </Flex>
      {/* END MOBILE Fixed Bottom Action Bar */}
    </>
  )
}